import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Race = () => (
  <Layout>
    <SEO title="Race" />
    <p>
        I'd suggest playing the race that you think you would enjoy playing as the most or is the most aesthetically pleasing to you! The gap in racial ability power is so tiny and often the best one depends on the situation. Here is what I've been saying for years: People who think that racials matter usually aren't skilled enough that their racial will make a difference in their ranking and the people who are good enough that their racial makes a difference don't need their racials to be where they are at. The only exception to this, in my opinion, is at the highest level during tournament play where you know who you're playing against.
    </p>
    <p>Playing a character you enjoy playing and identify with is more important to your long term success than any minor power gain you will recieve from picking the "optimal" race. You can probably take my word for it on this one, I've hit rank 1 playing a race that most people considered to be sub-optimal for my class. Lookin' cute is always the top priority!</p>
    <p>If you really have to pick the "best" one, here are some that you mostly cant go wrong with no matter what class you are but please heed my words above. If you don't enjoy logging in, you wont get better at the game!</p>
    <ul style={{listStyle: `none`,}}>
        <li>Alliance: Human, Nightelf</li>
        <li>Horde: Orc</li>
    </ul>
  </Layout>
)

export default Race
